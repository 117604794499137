<template>
	<div id="doctor">
		<div class="top">
			<div class="title">
				<h1>待审核医生列表 <i></i> </h1>
				<div class="opts">
					<div class="creatDoc" @click="creatorDoctor()">
						<img src="../../../assets/imgs/kong1.png">
						<span>新增医生</span>
					</div>
					<div class="waitDoc" @click="toDoctor()">
						<!-- <i class="waitTotal">{{waitTotal}}</i> -->
						<img src="../../../assets/imgs/kong3.png">
						<span>全部医生</span>
					</div>
				</div>
			</div>
			<div class="searth">
				<el-input @change="searthDoctorFun()" v-model="nameKeyword" prefix-icon="el-icon-search" clearable
					placeholder="搜索医生姓名/手机号/所在医院等关键词"></el-input>
				<!-- 	<el-select v-model="departVal" placeholder="按科室筛选" clearable filterable @change="getDoctorList()">
					<el-option v-for="item in department" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
				<el-select v-model="titVal" placeholder="按职称筛选" clearable filterable @change="getDoctorList()">
					<el-option v-for="item in title" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select> -->
				<el-button type="primary" @click="searthDoctorFun()">搜索</el-button>
			</div>
		</div>
		<div class="list">
			<div class="item" v-for="item in doctorList" :key="item.id" @click="toDoctorInfo(item)">
				<div class="pos succ" v-if="item.status == 'SUCCESS'">已审核</div>
				<div class="pos wait" v-else>待审核</div>
				<div class="ava"> {{item.fullName}} </div>
				<div class="docInfo">
					<div class="name">{{item.fullName?item.fullName:item.phoneNumber}}</div>
					<div class="hospital">{{item.hospital}}</div>
					<div class="btn">查看详情</div>
					<div class="tit">
						<span v-if="item.department">
							<i class="el-icon-timer"></i>
							{{item.department}}</span>
						<span v-if="item.title">
							<i class="el-icon-location"></i>
							{{item.title}}
						</span>
					</div>
				</div>
			</div>

			<div class="null" v-if="!doctorList[0]">
				<img src="../../../assets/imgs/EmptyState.png" alt="">
				<p>没有待审核用户</p>
			</div>
		</div>

		<el-pagination background layout="prev, pager, next" :total="total" :page-size="size" :current-page.sync="page"
			class="pagination" @current-change="getDoctorList" :hide-on-single-page="false"></el-pagination>
	</div>
</template>

<script>
	// import doctorinfoVue from '../z_doctorinfo/doctorinfo.vue';
	import {
		doctorList,
		waitDoctors
	} from '@/request/api.js';
	import title from '@/assets/title.json';
	import department from '@/assets/department.json';
	export default {
		name: 'doctor',
		data() {
			return {
				nameKeyword: '',
				hospitalKeywords: '',
				phonenumberKeywords: '',
				page: 1,
				size: 10,
				total: 0,
				doctorList: '',
				waitDocs: '',
				waitTotal: 0,
				department: '',
				departVal: '',
				title: '',
				titVal: ''
			}
		},
		created() {
			this.title = title;
			this.department = department;
			this.getDoctorList();
		},
		mounted() {},
		methods: {
			// // 获取待审核医生
			// getwaitDoctors() {
			// 	waitDoctors().then(res => {
			// 		if (res.code == 200) {
			// 			this.waitDocs = res.data.records;
			// 			this.waitTotal = res.data.total;
			// 		}
			// 	})
			// },

			toDoctor() {
				this.$router.push({
					name: "doctor"
				})
			},

			// 查询医生信息详情
			toDoctorInfo(item) {
				console.log(item.id)
				this.$router.push({
					name: 'doctorinfo',
					query: {
						dId: item.id
					}
				})
			},

			// 新增医生
			creatorDoctor() {
				this.$router.push({
					name: 'creatdoc'
				})
			},

			// 搜索操作
			searthDoctorFun() {
				this.page = 1;
				this.getDoctorList();
			},

			// 获取医生列表
			getDoctorList() {
				let data = {
					current: this.page,
					size: this.size
				};
				if (this.nameKeyword) data.fullName = this.nameKeyword;
				if (this.hospitalKeywords) data.hospital = this.hospitalKeywords;
				if (this.phonenumberKeywords) data.phoneNumber = this.phonenumberKeywords;
				if (this.departVal) data.department = this.departVal;
				if (this.titVal) data.title = this.titVal;
				waitDoctors(data).then(res => {
					if (res.code == 200) {
						this.doctorList = res.data.records;
						this.total = res.data.total;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#doctor {
		padding: 20px;

		.top {
			padding: 20px;
			border-radius: 10px;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);
			margin-bottom: 20px;
		}

		.title {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 20px;

			h1 {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.opts {
				display: flex;
				flex-direction: row;

				>div {
					margin-left: 20px;
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 10px;
					border-radius: 30px;
					position: relative;

					* {
						cursor: pointer;
					}

					.waitTotal {
						position: absolute;
						top: -3px;
						right: -3px;
						border: 4px solid #fff;
						background-color: #FB3449;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						font-style: normal;
						width: 25px;
						height: 25px;
						border-radius: 50%;
					}

					span {
						font-size: 17px;
						width: 140px;
						margin-right: 10px;
						text-align: center;
					}

					img {
						width: 40px;
						height: 40px;
					}
				}

				.creatDoc {
					background-color: #D5E5F7;
				}

				.waitDoc {
					background-color: #F3FFDB;
				}
			}
		}

		.searth {
			display: flex;
			flex-direction: row;
			// margin-bottom: 20px;

			.el-input {
				flex: 3;
				margin-right: 20px;
			}

			.el-select {
				flex: 2;
				margin-right: 20px;
			}

			.el-button {}
		}

		.list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-bottom: 30px;
			// justify-content: space-around;

			.item {
				// width: 300px;
				width: calc(20% - 20px);
				padding: 40px 20px;
				display: flex;
				cursor: pointer;
				margin-right: 20px;
				margin-bottom: 10px;
				border-radius: 30px;
				transition: all .3s;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				position: relative;

				.pos {
					position: absolute;
					left: 0;
					top: 40px;
					color: #fff;
					padding: 5px 20px 5px 10px;
					font-size: 12px;
					line-height: 1;

					&::after {
						content: '';
						position: absolute;
						right: 0;
						top: 0;
						transition: all .3s;
						border: 11px solid transparent;
						border-right-color: #fff;
					}
				}

				.wait {
					background-color: #D43130;
				}

				.succ {
					background-color: #1B69FD;
				}

				&:hover {
					box-shadow: 0 0 10px rgba(0, 0, 0, .1);
					border-color: rgb(0, 122, 255);
					background-color: #E4ECF5;

					.ava {
						background-color: rgb(0, 122, 255);
						color: #fff;
					}

					.pos {
						&::after {
							border-right-color: #E4ECF5;
						}
					}
				}

				* {
					cursor: pointer;
				}

				.ava {
					width: 90px;
					height: 90px;
					padding: 14px;
					margin: 0 auto 10px;
					overflow: hidden;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					border-radius: 50%;
					transition: all .3s;
					background-color: #D1D2D6;
					border: 4px solid #fff;
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
				}

				.docInfo {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 3px 5px;
					width: 100%;

					.name {
						font-size: 20px;
						color: #000;
						display: flex;
						flex-direction: row;
						margin-bottom: 10px;
						justify-content: space-around;
					}

					.hospital {
						font-size: 14px;
						color: #808080;
						display: flex;
						flex-direction: row;
						margin-bottom: 10px;
						justify-content: space-around;
					}

					.btn {
						width: 160px;
						text-align: center;
						padding: 10px 25px;
						font-size: 14px;
						line-height: 1;
						border-radius: 12px;
						color: #1B69FD;
						background-color: #D4E9FF;
					}

					.tit {
						width: 100%;
						font-size: 14px;
						display: flex;
						border-radius: 30px;
						flex-direction: row;
						justify-content: space-around;
						box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
						margin-top: 34px;
						overflow: hidden;

						span {
							flex: 1;
							padding: 6px;
							background-color: #FAFCFE;
							text-align: center;
							border-right: 1px solid #E4ECF5;
							color: #B1B5BA;

							&:last-child {
								border: none;
							}
						}
					}
				}
			}

			.null {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 100px;

				img {
					width: 200px;
				}

				p {
					font-size: 18px;
					color: #A6A6A6;
					margin-top: 30px;
				}
			}
		}

		.pagination {
			text-align: center;
		}
	}
</style>